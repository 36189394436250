<template>
  <div class="community-detail">
    <div class="community-detail-header">
      <div class="community-detail-header-left">
        <img :src="detailInfo?.community?.communityPicture" alt="" />
        <el-button size="small" @click="onJoin" type="primary">
          加入社区
        </el-button>
      </div>
      <div class="community-detail-header-right">
        <div class="top">
          <div class="top-left">
            <h1>{{ detailInfo?.community?.communityName }}</h1>
            <h2>
              <img
                :src="
                  detailInfo?.community?.communityIntroductionPicture ||
                  require('@/assets/kyyl-nt.png')
                "
                alt=""
              />
              <span>{{ detailInfo?.community?.companyName }}</span>
              <el-tag color="transparent" type="success">社长</el-tag>
            </h2>
          </div>
        </div>
        <div class="bottom">
          {{ detailInfo?.community?.companyScale }}
        </div>
      </div>
    </div>
    <div class="community-detail-tabs">
      <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane label="社区介绍" name="info">
          <div
            class="info"
            v-html="detailInfo?.communityDetail?.communityIntroduction"
          />
        </el-tab-pane>
        <el-tab-pane label="社区成员" name="members">
          <div class="members">
            <div
              v-for="(community, i) in detailInfo.communityMembers"
              :key="i"
              class="members-cell"
            >
              <img
                :src="
                  community.companyLogo ||
                  require('@/assets/kyyl-nt.png')
                "
                alt=""
              />
              <div>
                <h1 v-if="community.displayName == 'Y'">
                  {{ community.companyName }}
                </h1>
                <h1 v-if="community.displayName != 'Y'">
                  {{ hideCharacters(community.companyName, 3, 6) }}
                </h1>
                <p v-if="community.tags">
                  <el-tag
                    v-for="(tag, index) in community.tags.split(',')"
                    :key="index"
                    size="small"
                  >
                    {{ tag }}
                  </el-tag>
                </p>
                <p>
                  <span>简介：</span>
                  {{ community.companyIntro }}
                </p>
                <p>
                  <span>服务：</span>
                  {{ community.companyService }}
                </p>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="成功案例" name="success">
          <div class="success">
            <div
              v-for="(communityCase, i) in detailInfo.communityCases"
              :key="i"
              class="success-cell"
            >
              <img
                :src="
                  communityCase.casePicture ||
                  require('@/assets/kyyl-nt.png')
                "
                alt=""
              />
              <div>
                <h1 v-if="communityCase.caseNeedDisplay == 'Y'">
                  {{ communityCase.caseTitle }}
                  <span>{{ communityCase.caseLevel }}</span>
                </h1>
                <h1 v-if="communityCase.caseNeedDisplay != 'Y'">
                  {{ hideCharacters(communityCase.caseTitle, 3, 6) }}
                  <span>{{ communityCase.caseLevel }}</span>
                </h1>
                <p v-html="communityCase.caseIntroduction" />
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="联系方式" name="contact">
          <div v-html="detailInfo?.community?.contactUs"></div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog title="加入社区" width="40%" :visible.sync="dialogFormVisible">
      <el-form ref="form" :model="formData" :rules="rules" label-width="140px">
        <el-form-item label="公司名称" prop="companyName">
          <el-input style="width: 400px" v-model="formData.companyName" />
        </el-form-item>
        <el-form-item label="公司头像" prop="companyLogo">
          <el-upload
            class="avatar-uploader"
            action="/new_gateway/codepower-rest-api/communityAdmin/upload"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
          >
            <img
              v-if="formData.companyLogo"
              :src="formData.companyLogo"
              class="avatar"
              alt=""
            />
            <i v-else class="avatar-uploader-icon el-icon-plus" />
          </el-upload>
        </el-form-item>
        <el-form-item label="公司简介" prop="companyIntro">
          <el-input
            v-model="formData.companyIntro"
            :autosize="{ minRows: 8, maxRows: 16 }"
            type="textarea"
            placeholder="请输入内容"
          />
        </el-form-item>
        <el-form-item label="公司联系人名字" prop="companyContactName">
          <el-input
            v-model="formData.companyContactName"
            autocomplete="off"
            placeholder="请输入公司联系人名字"
          />
        </el-form-item>
        <el-form-item label="公司联系人手机号" prop="contactPhone">
          <el-input
            v-model="formData.contactPhone"
            autocomplete="off"
            placeholder="请输入公司联系人手机号"
          />
        </el-form-item>
        <el-form-item label="公司服务" prop="companyService">
          <el-input style="width: 400px" v-model="formData.companyService" />
        </el-form-item>
        <el-form-item label="公司类型" prop="companyType">
          <!-- 下拉选择框-->
          <el-select v-model="formData.companyType" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="公司标签" prop="tags">
          <el-input style="width: 400px" v-model="formData.tags" />
          <p>公司有多个标签时，需`,`隔开，例如：A,B,C</p>
        </el-form-item>
        <el-form-item label="显示公司名称" prop="displayName" v-show="false">
          <el-switch
            v-model="formData.displayName"
            active-value="Y"
            inactive-value="N"
          />
        </el-form-item>
        <el-form-item label="公司省份/地市" prop="city">
          <v-distpicker
            hide-area
            @change-province="onChangeProvince"
            @change-city="onChangeCity"
          />
        </el-form-item>
        <el-form-item label="申请加入社区理由" prop="reason">
          <el-input
            v-model="formData.reason"
            autocomplete="off"
            type="textarea"
            rows="4"
            placeholder="请输入申请加入社区理由"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm"> 确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCommunityDetail, joinCommunity } from "@/api/community";
import VDistpicker from "v-distpicker";
import { mapGetters } from "vuex";

export default {
  components: { VDistpicker },
  data() {
    return {
      activeName: "info",
      options: [
        {
          value: "资源",
          label: "资源",
        },
        {
          value: "技术",
          label: "技术",
        },
        {
          value: "实施",
          label: "实施",
        },
        {
          value: "用户",
          label: "用户",
        },
      ],
      detailInfo: {
        communityMembers: [],
        communityCases: [],
      },
      dialogFormVisible: false,
      formData: {
        communityId: "",
        companyLogo: "",
        companyName: "",
        companyIntro: "",
        companyService: "",
        companyContactName: "",
        contactPhone: "",
        displayName: "",
        companyType: "",
        tags: "",
        city: "",
        province: "",
        reason: "",
      },
      rules: {
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        companyLogo: [
          { required: true, message: "请上传公司头像", trigger: "blur" },
        ],
        companyIntro: [
          { required: true, message: "请输入公司简介", trigger: "blur" },
        ],
        companyContactName: [
          { required: true, message: "请输入公司联系人名字", trigger: "blur" },
        ],
        contactPhone: [
          {
            required: true,
            message: "请输入公司联系人手机号",
            trigger: "blur",
          },
        ],
        companyType: [
          { required: true, message: "请输入公司类型", trigger: "blur" },
        ],
        companyService: [
          { required: true, message: "请输入公司服务", trigger: "blur" },
        ],
        tags: [{ required: true, message: "请输入公司标签", trigger: "blur" }],
        reason: [
          { required: true, message: "请输入社区申请理由", trigger: "blur" },
        ],
        province: [
          { required: true, message: "请输入社区省份", trigger: "blur" },
        ],
        city: [{ required: true, message: "请输入社区地市", trigger: "blur" }],
      },
    };
  },

  computed: {
    ...mapGetters("user", ["getUserInfo"]),
  },

  methods: {
    async initData() {
      const { params } = this.$route;
      try {
        const { data } = await getCommunityDetail(params.id);
        this.detailInfo = data;
        console.log(data);
      } catch (e) {
        console.log(e);
      }
    },

    onJoin() {
      this.$message.info("请前往 PC 端加入社区");
    },

    handleAvatarSuccess(data) {
      if (data.code === 200) {
        this.formData.companyLogo = data.data;
      } else {
        this.$message.error(data.message);
      }
      console.log(data);
    },
    openAdminEditCommunity(communityId) {
      console.log("click openAdminEditCommunity");
      window.open(
        "http://opencom.com.cn:40008/#/community/detail/" + communityId,
      );
    },
    hideCharacters(text, start, end) {
      const hiddenCharacters = "*".repeat(end - start + 1);
      const visiblePart =
        text.slice(0, start - 1) + hiddenCharacters + text.slice(end);
      return visiblePart;
    },

    submitForm() {
      this.formData.communityId = this.$route.params.id;
      this.$refs.form.validate((valid) => {
        if (valid) {
          joinCommunity(this.formData).then(() => {
            this.$message.success("申请成功");
            this.dialogFormVisible = false;
          });
        } else {
          console.log("请填写必填内容");
          return false;
        }
      });
    },

    onChangeProvince(data) {
      console.log(data);
      this.formData.province = data.value;
    },

    onChangeCity(data) {
      console.log(data);
      this.formData.city = data.value;
    },
  },

  created() {
    this.initData();
  },
};
</script>
<style>
.el-dialog__body {
  max-height: 60vh;
  overflow-y: auto;
}
.avatar-uploader .el-upload {
  border: 1px dashed #eee;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.avatar-uploader-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #8c939d;
  width: 90px;
  height: 90px;
  text-align: center;
}
</style>
<style scoped lang="scss">
.avatar-uploader .avatar {
  width: 90px;
  height: 90px;
  display: block;
}
.community-detail {
  background-color: #f4f8ff;
  padding: 32px 0;

  h1,
  h2,
  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  &-header {
    padding: 16px;
    background-color: white;
    border-radius: 6px;
    width: calc(100vw - 32px);
    display: flex;

    &-left {
      width: 80px;

      img {
        width: 80px;
        height: 80px;
        margin-bottom: 16px;
      }
    }

    &-right {
      flex: 1;
      margin-left: 20px;

      .top {
        display: flex;
        justify-content: space-between;

        h1 {
          font-size: 24px;
          font-weight: bold;
          color: #262c33;
        }

        h2 {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: bold;
          color: #262c33;
          margin-top: 16px;

          img {
            height: 38px;
          }

          span {
            margin: 0 18px 0 8px;
          }
        }
      }

      .bottom {
        margin-top: 16px;
        font-size: 18px;
        color: #262c33;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  &-tabs {
    padding: 16px;
    background-color: white;
    border-radius: 6px;
    width: calc(100vw - 32px);
    margin: 24px auto 0;

    .info {
      font-size: 18px;
      color: #262c33;

      p + p {
        margin-top: 20px;
      }

      img {
        display: block;
        width: 100%;
        margin: 32px auto 0;
      }
    }

    .members,
    .success {
      &-cell {
        display: flex;
        padding: 16px 0;
        border-bottom: 1px solid #eee;

        img {
          width: 50px;
          height: 50px;
          margin-right: 16px;
        }

        h1 {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: bold;
          color: #262c33;

          .el-tag {
            margin-left: 8px;
          }

          span {
            font-size: 14px;
            color: #666;
            margin-left: 20px;
          }
        }

        p {
          display: flex;
          flex-wrap: wrap;
          font-size: 16px;
          color: #262c33;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-top: 8px;

          span {
            font-weight: bold;
          }

          .el-tag + .el-tag {
            margin-left: 12px;
          }

          img {
            width: 30%;
          }
        }

        p + p {
          margin-top: 16px;
        }
      }

      &-more {
        padding: 24px 0;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #3a89fe;
      }
    }
  }
}
</style>
